
import React from 'react';
import HeaderComponent from './components/commonComponent/HeaderComponent';

function Attorney() {
  return (
    <>

        <HeaderComponent title="Attorney" />
   
        <div className="container-fluid py-5">
        <div className="container py-5">
            <div className="row">

                <h1>this is Attorney</h1>
                
                </div>
                <br/>
                <div className="row">

                <p>this is Attorney</p>
                
                </div>
        </div>
        </div>

    

    </>
  );
}

export default Attorney;
