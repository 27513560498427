
import React from 'react';
import HeaderComponent from './commonComponent/HeaderComponent';

function Services() {
  return (
    <>

        <HeaderComponent title="Services" />
   
        <div className="container-fluid py-5">
        <div className="container py-5">
            <div className="row">

                <h1>this is Services</h1>
                
                </div>
                <br/>
                <div className="row">

                <p>this is Services</p>
                
                </div>
        </div>
        </div>

    

    </>
  );
}

export default Services;
